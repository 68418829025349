import React from 'react';
import SectionText from '../ui/SectionText';
import * as FaIcons from 'react-icons/fa';
import { API_URL } from '../../utils/data';

const Address = ({ addressData }: any) => {
    return (
        <section className='p-container bg-darkColor main-padding overflow-hidden'>
            <SectionText title={addressData?.mainTitle} subtitle={addressData?.content} />
            <div className='flex-between xs:flex-col sm:flex-row'>
                <div>
                    <ul className='flex flex-col gap-3 '>
                        {addressData?.homePageSectionProperties.map((step: any, index: number) => (
                            <li key={index} className=' flex gap-1'>
                                -<p>{step?.title}:</p>
                                <p>{step?.content}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <div className=' w-full h-72'>
                        <img className='w-full h-full' src={`${API_URL}/imgs/${addressData?.image}`} alt="vision" loading='lazy' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Address;
