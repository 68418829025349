import React, { useEffect, useState } from 'react'
import Hero from './Hero'
import About from './About'
import Services from './Services'
import Start from './Start'
import Vision from './Vision'
import Partner from './Partner'
import Footer from '../common/Footer'
import Loading from '../ui/Loading'
import { fetchPageData } from '../../utils/data'
import useFetch from '../../custom hooks/UseFetch'
import Thanks from './Thanks'

const Home = () => {
    const { data: pagesData } = useFetch<any>(fetchPageData);
    const [heroData, setHeroData] = useState<any>(null);
    const [aboutData, setAboutData] = useState<any>(null);
    const [startData, setStartData] = useState<any>(null);
    const [serviceData, setServiceData] = useState<any>(null);
    const [thanksData, setThanksData] = useState<any>(null);
    const [visionData, setVisionData] = useState<any>(null);
    useEffect(() => {
        if (pagesData?.data) {
            const homePageSelection = pagesData.data.filter((item: any) => item.number === 1);
            if (homePageSelection.length > 0) {
                const homePage = homePageSelection[0];
                const hero = homePage.homePageSections[0];
                const about = homePage.homePageSections[1]
                const start = homePage.homePageSections[2]
                const service = homePage.homePageSections[4]
                const thanks = homePage.homePageSections[3]
                const vision = homePage.homePageSections[5]
                setHeroData(hero);
                setAboutData(about);
                setStartData(start);
                setServiceData(service);
                setThanksData(thanks);
                setVisionData(vision)
            }
        }
    }, [pagesData]);
    console.log("heroData",heroData)
    if (!heroData) {
        return <Loading />;
    }
    return (
        <div>
            <Hero heroData={heroData}/>
            <About aboutData={aboutData} />
            <Start startData={startData} />
            <Services serviceData={serviceData}/>
            <Thanks thanksData={thanksData}/>
            <Vision visionData={visionData} />
            <Partner />
            <Footer />
        </div>
    )
}

export default Home