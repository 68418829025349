
import { API_URL } from '../../utils/data'
import { useTranslation } from 'react-i18next'
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { useNavigate,  } from 'react-router-dom';

const Fastival = ({ blogData }: any) => {
    const { setActiveBlog} = useStateContext() as GlobalStateContext;
    const navigate=useNavigate()
    const{t}=useTranslation();
    return (
        <div className='main-padding p-container'>
        <div className='grid lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-10'>
            {blogData?.homePageSectionProperties?.map((blog: any, index: number) => (
                <div className='border-2 rounded-xl grid  shadow-md p-6 ' key={index}>
                    <div className='w-full h-60'>
                        <img className='w-full h-full' src={`${API_URL}/imgs/${blog.image}`} alt="product-brand" />
                    </div>
                    <h2 className='text-xl text-secondry font-bold pt-2'>{blog.title?blog.title:t("no-title")}</h2>
                    <p className='text-lg text-primary pt-2'>
                        {blog?.content?.length > 100
                            ? `${blog.content.slice(0, 100)}...`
                            : blog.content}
                    </p>
                    <button
                        onClick={()=>{
                            setActiveBlog(blog);
                            localStorage.setItem("activeBlog",JSON.stringify(blog))
                            navigate("/karama/details")
                        }}
                        className='self-end w-fit mt-5 text-green-500 text-xl font-bold flex-center'>
                        {t("show-more")} &gt;
                    </button>
                </div>
            ))}
        </div>
    </div>
    )
}

export default Fastival