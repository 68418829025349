
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MMtech, { socialMediaLinksMMtech } from '../ui/MMtech';
import useFetch from '../../custom hooks/UseFetch';
import { API_URL, fetchSiteInfo } from '../../utils/data';
import { FaYoutube, FaInstagram, FaTwitter } from 'react-icons/fa';
import { BiLogoFacebook } from 'react-icons/bi';

const Footer = () => {
    const { data: SiteInfoData } = useFetch<any>(fetchSiteInfo);
    const siteInfo = SiteInfoData?.data[0]
    const { t } = useTranslation();
    return (
        <div className=' bg-secondry text-white overflow-hidden'>
            <div className=' flex-center flex-col gap-10  p-container main-padding '>
                <div className='flex-center flex-col'>
                    <div className=' w-60'>
                        <img className=' w-full' src={`${API_URL}/imgs/${siteInfo?.image}`} alt='logo' loading='lazy' />
                    </div>
                    <p className='text-darkColor'>{siteInfo?.description}</p>
                </div>
                <div className=' text-xl flex gap-5  py-3'>
                        <div>
                            {siteInfo?.facebook ?
                                <Link to={siteInfo.facebook}>
                                    <p className='bg-white rounded-full text-secondry w-12 h-12 text-xl font-bold flex-center'><BiLogoFacebook /></p>
                                    
                                </Link> : null
                            }
                        </div>
                        <div>
                            {siteInfo?.youtube ?
                                <Link to={siteInfo?.youtube}>
                                    <p className='bg-white rounded-full text-secondry w-12 h-12 text-lg font-bold flex-center'><FaYoutube /></p>
                                    
                                </Link> : null
                            }
                        </div>
                        <div>
                            {siteInfo?.instagrham ?
                                <Link to={siteInfo.instagrham}>
                                    <p className='bg-white rounded-full text-secondry w-12 h-12 text-lg font-bold flex-center'><FaInstagram  /></p>
                                    
                                </Link> : null
                            }
                        </div>
                        <div>
                            {siteInfo?.twitter ?
                                <Link to={siteInfo.twitter}>
                                    <p className='bg-white rounded-full text-secondry w-12 h-12 text-lg font-bold flex-center'><FaTwitter  /></p>
                                    
                                </Link> : null
                            }
                        </div>
                    </div>
            </div>
            <MMtech />
        </div>
    )
}

export default Footer