import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { DEV_MODE, fetchPages, fetchSections, handleAddItem, handleLogout } from '../../utils/data';
import useFetch from '../../custom hooks/UseFetch';
import { NavLink } from 'react-router-dom';
import DeletePopUp from './DeletePopUp';
import AddForm from './AddForm';
import { dashbordLinks_ar, dashbordLinks_en } from '../../constant';

interface SidebarProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Sidebar = ({ setOpen }: SidebarProps) => {
    const { t,i18n } = useTranslation();
    const { showAddForm, showEditForm, setShowdeletePopup, setShowAddForm, showdeletePopup, setSection ,showAddPage, setShowAddPage} = useStateContext() as GlobalStateContext;
    const { data: pagesData } = useFetch<any>(fetchPages);
    const pages = pagesData?.data;
    const [openLinks, setOpenLinks] = useState<number | null>(null);
    const [pageId, setPageId] = useState<any>("");
    const [homePageId, sethomePageId] = useState<any>("");
    const [selectedPage, setSelectedPage] = useState<any | null>(null);
    const [sections, setSections] = useState<any>([]);
    const isDisabled = showAddForm || showEditForm || showdeletePopup || showAddPage;
    const dashbordLinks=i18n.language==="ar"?dashbordLinks_ar:dashbordLinks_en;
    // Fetch sections when pageId changes
    useEffect(() => {
        if (pageId) {
            fetchSections(pageId).then((res) => {
                setSections(res?.data || []);
            });
            console.log("sec", sections)
        }
        // eslint-disable-next-line
    }, [pageId]);

    const toggleLink = (index: number, page: any) => {
        if (openLinks === index) {
            setOpenLinks(null);
            setPageId("");
        } else {
            setOpenLinks(index);
            setPageId(page.id.value);
        }
    };
    const handleSaveSection = (section: any) => {
        setSection(section);
        localStorage.setItem("section", JSON.stringify(section));
    }
    const handleAddSection = (data: any) => {
        data.homePageId=homePageId;
        handleAddItem("HomePageManagement/Update/PageSection", data)
        console.log("New Product Data:", data);
    };
    const handleAddPage = (data: any) => {
        handleAddItem("HomePageManagement/Update/Page", data)
        console.log("New Product Data:", data);
    };
    console.log("pages",pages)
    if (!pages) return null;
 
    return (
        <div className='flex justify-between no-scroll h-full flex-col bg-[#191919]'>
            <div>
                <div className='w-full bg-black text-4xl font-bold px-6 py-8'>
                    <h1>{t("dashbord")}</h1>
                </div>

                <div className={`flex flex-col gap-4 ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}>
                    {DEV_MODE&&<button
                        onClick={() => {
                            setShowAddPage(!showAddPage)
                        }} className='bg-green-600 flex-center font-bold py-2 text-sm text-white'>
                        {t("add-page")}
                    </button>}
                    <ul className='pt-8'>
                        {pages.map((page: any, index: number) => (
                            <li key={index}>
                                <div className=' flex-between pe-2'>
                                    <button
                                        type='button'
                                        onClick={() => toggleLink(index, page)}
                                        className={`flex items-center py-3 px-6 gap-3 w-full text-lg text-weakColor ${openLinks === index ? 'text-white px-2 border-s-8 border-gold' : ''}`}
                                    >
                                        <p className='text-secondry text-xl'>{page.icon}</p>
                                        <p>{page.name}</p>
                                        <p className='text-lg'>{openLinks === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                                    </button>
                                    {DEV_MODE&&<div className=' flex-center gap-2'>
                                        <button
                                            onClick={() => {
                                                setShowAddForm(true)
                                                sethomePageId(page.id)
                                            }} className='bg-green-600 rounded-full flex-center w-7 h-7 font-bold text-sm text-white'>
                                            +
                                        </button>
                                        <button
                                            onClick={() => {
                                                setSelectedPage(page);
                                                setShowdeletePopup(true)
                                            }}
                                            className='bg-red-600 rounded-full flex-center w-7 h-7 text-sm text-white'>
                                            X
                                        </button>
                                    </div>}
                                </div>
                                {openLinks === index && sections.length > 0 && (
                                    <div className='flex flex-col w-full  text-darkColor px-14 gap-5'>
                                        {sections.slice().reverse().map((section: any, index: number) => (
                                            <NavLink
                                                key={index}
                                                onClick={() => handleSaveSection(section)}
                                                to={`content-manage/${section.id.value}`}
                                                className={({ isActive }) =>
                                                    `${isActive ? 'bg-secondry text-white px-3 py-1 ' : ''} w-full transition-all duration-300`
                                                }>
                                                {t("section") + " " + (index + 1)}
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                    <div className=' px-8 text-lg flex flex-col gap-3'>
                        {dashbordLinks.map((link,index)=>(
                            <NavLink key={index} to={link.href} className={`flex  gap-2 items-center`}>
                                <p className=' text-xl text-gold font-bold'>{link.icon}</p>
                                <p>{link.name}</p> 
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className='px-6 py-8'>
                    <button onClick={handleLogout} className='text-red-600 text-xl font-semibold'>
                        {t("logout")}
                    </button>
                </div>
                {showdeletePopup && selectedPage && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={`HomePageManagement/Delete/Page/${selectedPage?.id?.value}`} id={selectedPage?.id?.value} content={true} />}
                 {showAddForm && <AddForm
                    fields={[
                        { name: 'mainTitle', type: 'text', label: t('section-name') },
                        { name: 'subTitle', type: 'text', label: t('subTitle') },
                        { name: 'link', type: 'text', label: t('link') },
                        { name: 'linkTitle', type: 'text', label: t('linkTitle') },
                        { name: 'content', type: 'text', label: t('details') },
                        { name: 'image', type: 'file', label: t('image') }
                    ]}
                    onSubmit={handleAddSection}
                />}
                {showAddPage && <AddForm
                    fields={[
                        { name: 'name', type: 'text', label: t('section-name') },
                        
                    ]}
                    onSubmit={handleAddPage}
                />}
            </div>
        </div>
    );
};

export default Sidebar;
