import React from 'react'
import Home from '../components/events/Home'

const Events = () => {
  return (
    <div>
        <Home/>
    </div>
  )
}

export default Events