import React from 'react'
import { useTranslation } from 'react-i18next';
import SectionText from '../ui/SectionText';
import { API_URL } from '../../utils/data';

const Vision = ({visionData}:any) => {
    const { t } = useTranslation();
    return (
        <section className=' main-padding'>
            <div className=' overflow-hidden flex-between xs:flex-col sm:flex-row gap-10'>
                <div data-aos="fade-down" className='flex-1 p-container xs:text-center md:text-start'>
                    <SectionText title={visionData?.mainTitle} subtitle={visionData?.subTitle} />
                    <div data-aos="slide-up" className=' flex-center flex-1'>
                        <p className="text-gray-500 font-bold">"{visionData?.content}"</p>
                    </div>
                </div>
                <div className='flex-1 flex-center'>
                    <div className=' w-full h-full'>
                        <img className='w-full h-full' src={`${API_URL}/imgs/${visionData?.image}`}  alt="vision" loading='lazy'/>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Vision