import { useEffect, useState } from 'react'
import Sidebar from '../../components/dashbord/Sidebar'
import Logo from '../../components/ui/Logo'
import { Outlet, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import ScrollToTop from '../../components/ui/ScrollToTop';
import Cookies from 'js-cookie';

const isAuthenticated = () => {
    const token = Cookies.get('token');
    return !!token;
};
const DashbordLayout = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
    if (!isAuthenticated()) {
        navigate('/login');
    }
    }, [navigate]);
    return (
            <div className='flex h-screen text-white'>
            {
                open ?
                    <div className={`fixed bg-[#00000090] z-40 md:hidden xs:flex w-full  h-full top-0 left-0 `}>
                    </div> : ""
            }
            <div className={` transition-all ease-linear fixed right-0 top-0  z-50  md:w-2/6 lg:w-1/5  h-full bg-secondry ${open ? "xs:w-4/6" : "xs:w-0 overflow-hidden"}`}>
                <button type='button' onClick={() => setOpen(false)} title='close menu' className={` ${open ? "flex" : "hidden"} absolute top-10 -left-10 z-50 border-[1px] p-1 text-xl`}>
                    <IoMdClose />
                </button>
                <Sidebar setOpen={setOpen} />
            </div>
            <div className=' absolute left-0 h-full xs:w-full md:w-4/6 lg:w-4/5'>
                <div className=' fixed left-0 xs:w-full md:w-4/6 lg:w-4/5 z-30 shadow-sm top-0 bg-secondry py-2  px-6  flex-between '>
                    <Logo />
                    <div className='  text-lg font-bold text-white'>
                        <button type='button' onClick={() => setOpen(true)} title='open menu' className=' xs:flex  font-medium md:hidden border-[1px] border-secondry p-1 text-xl'>
                            <FaBars />
                        </button>
                    </div>
                </div>
                <div className='mt-20 bg-darkColor text-black h-full main-prop'>
                    <Outlet />
                </div>
            </div>
            <ScrollToTop/>
        </div>
    )
}

export default DashbordLayout