import React from 'react'
import {  Watch } from "react-loader-spinner";
const Loading = () => {
  return (
    <div className=' w-full h-screen flex-center'>
      <Watch
        visible={true}
        height="80"
        width="80"
        radius="48"
        color="#072530"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />

    </div>
  )
}

export default Loading