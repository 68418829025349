import React from 'react'
import { SectionTextProps } from '../../types'

const SectionText = ({ title,subtitle }: SectionTextProps) => {
    return (
        <div className=''>
            <h1 data-aos="fade-down" className='text-3xl font-extrabold  text-gold pb-5'>{title}</h1>
            <h2 data-aos="fade-down" data-aos-delay="100" className=' text-primary text-lg font-bold  pb-3'>{subtitle}</h2>
        </div> 
    )
}

export default SectionText