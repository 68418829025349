import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchProperties } from '../../utils/data';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import Property from '../../components/dashbord/Property';
import SectionDetails from '../../components/dashbord/SectionDetails';

const ContentPage = () => {
    const { pageId } = useParams();
    const [properties, setProperties] = useState([]);
    const { section, setSection } = useStateContext() as GlobalStateContext;
    useEffect(() => {
        if (pageId) {
            fetchProperties(pageId).then((res) => {
                setProperties(res?.data);
                if (!section) {
                    const savedSection = JSON.parse(localStorage.getItem("section") || '{}');
                    setSection(savedSection)
                }
            });
        }
        // eslint-disable-next-line
    }, [pageId]);

    return (
        <div className=' bg-darkColor pb-8 px-6'>
            <DashbordTitle title={new URLSearchParams(window.location.search).get('name') ?? ""} add={false} />
            <SectionDetails />
            <Property properties={properties} />
        </div>
    )
}

export default ContentPage