import { FaRegNewspaper } from 'react-icons/fa';
import { RiContactsLine } from "react-icons/ri";
import { IoIosHome, IoMdDocument } from "react-icons/io";
import { FaCircleInfo } from "react-icons/fa6";
import { TiMessages } from "react-icons/ti";
export const navlinks = [
    { name: "الرئيسية", href: "/", icon: <IoIosHome /> },
    { name: "أخبارنا", href: "/news", icon: <FaRegNewspaper /> },
    { name: "الاحداث", href: "/events", icon: <FaRegNewspaper /> },
    { name: " وثائق اعلامية", href: "/documents", icon: <IoMdDocument /> },
    { name: "تواصل معنا", href: "/contact-us", icon: <RiContactsLine /> },
    { name: "مهرجان الكرامة", href: "/karama", icon: <RiContactsLine /> }
]

export const navlinks_en = [
    { name: "Home", href: "/", icon: <IoIosHome /> },
    { name: "Our News", href: "/news", icon: <FaRegNewspaper /> },
    { name: "Events", href: "/events", icon: <FaRegNewspaper /> },
    { name: "Media Documents", href: "/documents", icon: <IoMdDocument /> },
    { name: "Contact Us", href: "/contact-us", icon: <RiContactsLine /> },
    { name: "Karama Fastival", href: "/karama", icon: <RiContactsLine /> }
]
export const dashbordLinks_ar = [
    { name: "المعلومات الرئيسية", href: "siteInfo", icon:<FaCircleInfo />},
    { name: "رسائل الموقع", href: "messages", icon: <TiMessages /> },
]
export const dashbordLinks_en = [
    { name: "Site Information", href: "siteInfo", icon: <FaCircleInfo /> },
    { name: "Website Messages", href: "messages", icon: <TiMessages /> },
]
export const services = [
    {
        title: "تدريب الفروسية",
        description: "دورات تدريبية لجميع المستويات لتعليم مهارات الفروسية، من الأساسيات إلى الاحتراف.",
        image: "/asset/gallery-2-465x383.jpg"
    },
    {
        title: "تدريب الرماية",
        description: "تدريب شامل على الرماية باستخدام أحدث الأدوات وتقنيات السلامة.",
        image: "/asset/pexels-pixabay-163533.jpg"
    },
    {
        title: "تنظيم البطولات",
        description: "نقوم بتنظيم بطولات الفروسية والرماية بأعلى معايير الاحترافية.",
        image: "/asset/page-1_img14.jpg"
    },
];

export const steps = [
    "املأ استمارة التسجيل",
    "احجز موعد التدريب الأول",
    "اختر الوقت",
    "استعد للبدء"
  ];

  export const partners=[
    {name:"1",logo:"/asset/market-1.png"},
    {name:"aa",logo:"/asset/market-2.png"},
    {name:"aa",logo:"/asset/market-3.png"},
    {name:"aa",logo:"/asset/market-4.png"},
    {name:"aa",logo:"/asset/market-5.png"}
  ]

