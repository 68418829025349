import React from 'react'
import Navbar from '../common/Navbar'
import MainLink from '../ui/MainLink';
import { API_URL } from '../../utils/data';

const Hero = ({heroData}:any) => {
    if(!heroData) return null
  return (
    <section
        style={{
            backgroundImage: `url('${API_URL}/imgs/${heroData?.image}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'left',
        }}
        className='h-screen relative overflow-hidden'>
            <Navbar />
            <div className='h-full flex-center' id='hero'>
                <div className='overlay'></div>
                <div className=' h-full p-container text-center absolute w-full text-white flex-center flex-col z-20' >
                    <div className=' flex-center flex-col w-full '>
                        <div>
                            <h1 data-aos="fade-up" className='xs:text-xl md:text-2xl font-extrabold text-gold  pb-10'>
                                {heroData?.subTitle}
                            </h1>
                            <div className='  flex-center'>
                            <p data-aos="fade-up" data-aos-delay="300" className='md:text-xl md:w-1/2 xs:w-full  xs:text:lg pb-10'>{heroData?.content}</p>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" className=' xs:flex-col sm:flex-row mt-10 w-fit flex gap-10'>
                            <MainLink title={heroData?.linkTitle} href={heroData?.link} />
                        </div>
                    </div>

                </div>
            </div>
        </section>
  )
}

export default Hero