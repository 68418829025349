import React from 'react'
import { partners } from '../../constant'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
const Partner = () => {
  return (
    <div className='overflow-hidden bg-primary hidden'>
        <div className=' relative'>
                    <Swiper
                        slidesPerView={5}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true }}
                        modules={[ Autoplay]}>
                                {partners.map((partner:any, index:number) => (
                                    <SwiperSlide key={index}>
                                        <div className='flex-center py-1' >
                                            <div className=' w-24 h-28 flex-center'>
                                                <img className='w-full' src={partner.logo} alt="partner" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                           
                    </Swiper>
                </div>
    </div>
  )
}

export default Partner