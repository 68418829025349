import React, { useEffect, useState } from 'react'
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import { API_URL } from '../../utils/data';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';

const BlogDetails = () => {
    const { activeBlog } = useStateContext() as GlobalStateContext;
    const [blog, setBlog] = useState<any>("")
    useEffect(() => {
        if (activeBlog) {
            setBlog(activeBlog);
            localStorage.setItem("activeBlog", JSON.stringify(activeBlog));
        } else {
            const savedBlog = localStorage.getItem("activeBlog");
            if (savedBlog) {
                try {
                    setBlog(JSON.parse(savedBlog));
                } catch (error) {
                    console.error("Error parsing saved blog data:", error);
                    localStorage.removeItem("activeBlog"); 
                }
            }
        }
    }, [activeBlog]);
    return (
        <div>
            <div className=' bg-secondry'>
                <Navbar />
            </div>
            <div className='main-padding p-container'>
                <div className=' relative p-6 rounded-md  flex-col' >
                    <div className=' w-full flex-center'>
                        <div className='w-96 h-60 overflow-hidden flex-center'>
                        <img className='w-full h-full' src={`${API_URL}/imgs/${blog.image}`} alt="product-brand" />
                    </div>
                    </div>
                    
                    <h2 className='text-2xl text-secondry py-3 font-bold'>{blog.title}</h2>
                    <p className='text-lg  text-primary'>
                        {blog.content}
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default BlogDetails