import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { API_URL, uploadImage } from '../../utils/data';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
interface AddFormProps {
    fields: { name: string; type: string; label: string }[];
    onSubmit: (data: any) => void;
}
const AddForm = ({ fields, onSubmit }: AddFormProps) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<any>({});
    const formRef = useRef<HTMLDivElement>(null);
    const {  setShowAddForm, setShowAddPage,setShowAddProperty} = useStateContext() as GlobalStateContext;

    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                event.stopPropagation(); // Prevent closing on outside click
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.type === 'file') {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                setIsUploading(true);
                try {
                    // Upload the image first
                    const imageName = await uploadImage(file);
                    setFormData({
                        ...formData,
                        [e.target.name]: imageName,
                    });
                    setImagePreview(imageName);
                } catch (error) {
                    console.error('Image upload failed:', error);
                } finally {
                    setIsUploading(false);
                }
            }
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = () => {
        onSubmit(formData);
        setShowAddForm(false);
    };
    return (
        <div className='fixed xs:w-full md:w-4/6 lg:w-4/5 inset-0  bg-[#000000e3] flex items-center justify-center '>
            <div ref={formRef} className=' w-2/3 no-scroll bg-white overflow-y-auto max-h-[70vh]'>
                <h2 className='text-2xl  font-bold bg-secondry w-full p-4'>{t("add-new-item")}</h2>
                <form onSubmit={(e) => e.preventDefault()} className='p-5 text-black'>
                    {fields.map((field) => (
                        <div key={field.name} className="py-3 text-xl font-semibold">
                            <label>{field.label}</label>
                            {field.type === 'textarea' ? (
                                <textarea
                                    name={field.name}
                                    onChange={handleInputChange}
                                    value={formData[field.name] || ''}
                                    className="border max-h-24  p-2 w-full focus:outline-none"
                                />
                            ) : field.type === 'file' ? (
                                <>
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        onChange={handleInputChange}
                                        className='border p-2 w-full focus:outline-none'
                                        disabled={isUploading}
                                    />
                                    {isUploading && <p>{t('Uploading image...')}</p>}
                                    {imagePreview && (
                                        <div className='mt-4'>
                                            <img
                                                src={`${API_URL}/imgs/${imagePreview}`}
                                                alt='Preview'
                                                className='w-32 h-32 object-cover'
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <input
                                    type={field.type}
                                    name={field.name}
                                    onChange={handleInputChange}
                                    value={formData[field.name] || ''}
                                    className="border p-2 w-full focus:outline-none"
                                />
                            )}
                        </div>
                    ))}
                    <div className=' grid '>
                        <div className=' justify-self-end flex text-xl gap-5 '>
                            <button onClick={() => {setShowAddForm(false);setShowAddPage(false);setShowAddProperty(false)}} className=' font-bold text-secondry' >{t("cancel")}</button>
                            |
                            <button onClick={handleSubmit} className="text-primary font-bold">{t("add")}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    )
}

export default AddForm