import React from 'react'
import { API_URL } from '../../utils/data';

const About = ({aboutData}:any) => {
  return (
    <section className='  p-container pt-16'>
        <div  className=' overflow-hidden flex-between xs:flex-col sm:flex-row gap-10'>
            <div data-aos="fade-down" className='flex-1'>
                <h2 className='text-3xl font-extrabold  text-gold pb-5'>{aboutData?.mainTitle}</h2>
                <h5 className='text-lg font-bold text-primary  pb-3'>{aboutData?.subTitle}</h5>
                <p className=' text-gray-500'>{aboutData?.content}</p>
            </div>
            <div data-aos="slide-up" className=' flex-center flex-1'>
                <img src={`${API_URL}/imgs/${aboutData?.image}`} alt="about-img" />
            </div>
        </div>
    </section>
  )
}

export default About