import React, { useEffect, useState } from 'react'
import useFetch from '../../custom hooks/UseFetch';
import { fetchPageData } from '../../utils/data';
import Loading from '../ui/Loading';
import Hero from '../contact/Hero';
import Footer from '../common/Footer';
import Blog from './Blog';

const Home = () => {
    const { data: pagesData } = useFetch<any>(fetchPageData);
    const [heroData, setHeroData] = useState<any>(null);
    const [blogData, setBlogData] = useState<any>(null);
    useEffect(() => {
        if (pagesData?.data) {
            const homePage = pagesData.data[2];
            const hero = homePage.homePageSections[0];
            const blog = homePage.homePageSections[1];
            setHeroData(hero);
            setBlogData(blog)
    }
    }, [pagesData]);
if (!heroData) {
    return <Loading />;
}
return (
    <div>
        <Hero heroData={heroData} />
        <Blog blogData={blogData} />
        <Footer />
    </div>
)
}

export default Home