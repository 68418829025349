import { Link } from "react-router-dom"
import { LinkProps } from "../../types"

const MainLink = ({ title, href }: LinkProps) => {
    return (
        <div>
            <Link to={`${href}`}
            className=' text-white xs:text-lg sm:text-xl bg-primary   px-4 py-2 rounded-full  transition-all duration-500 hover:text-primary hover:bg-white'>
                {title}
            </Link>
        </div>
    )
}

export default MainLink