import React from 'react'
import { API_URL } from '../../utils/data'

const Thanks = ({thanksData}:any) => {
  return (
    <section className=' bg-secondry '>
    <div className=' overflow-hidden flex-between xs:flex-col sm:flex-row gap-10'>
        <div data-aos="fade-down" className='flex-1 p-container xs:text-center sm:text-start'>
            <div data-aos="slide-up" className=' flex-center flex-1'>
                <p className="text-gray-500 xs:py-5 lg:p-0 font-bold">"{thanksData?.content}"</p>
            </div>
        </div>
        <div className='flex-1 flex-center'>
            <div className=' w-full h-full'>
                <img className='w-full h-full' src={`${API_URL}/imgs/${thanksData?.image}`} alt="vision" loading='lazy'/>
            </div>
        </div>

    </div>
</section>
  )
}

export default Thanks