import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ui/ScrollToTop";
import Loading from "../components/ui/Loading";
import useFetch from "../custom hooks/UseFetch";
import { fetchSiteInfo } from "../utils/data";

function App() {
    const { i18n } = useTranslation();
 const { data: productsData, loading } = useFetch<any>(fetchSiteInfo);
    const siteInfo = productsData?.data[0]
    console.log(siteInfo)
    if (siteInfo) {
        document.title = siteInfo.name;
    }
    if (loading) return <Loading /> 
    return (
        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <ScrollToTop />
            <Outlet />
        </div>
    );
}
export default App;
