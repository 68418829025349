import React, { useEffect, useState } from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchPageData } from '../../utils/data';
import Loading from '../ui/Loading';

const Home = () => {
    const { t } = useTranslation();
    const { data: pagesData } = useFetch<any>(fetchPageData);
    const [eventData, setEventData] = useState<any>(null);
    const [timeLeft, setTimeLeft] = useState<any>(null);

    useEffect(() => {
        if (pagesData?.data && pagesData.data.length > 1) {
            const homePage = pagesData.data[4];
            const event = homePage.homePageSections[0];
            setEventData(event);
        }
    }, [pagesData]);

    useEffect(() => {
        if (eventData?.subTitle) {
            const calculateTimeLeft = () => {
                const eventDate = new Date(eventData?.subTitle).getTime();
                const currentTime = new Date().getTime();
                const timeLeft = eventDate - currentTime;

                if (timeLeft <= 0) return null;

                const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                return { days, hours, minutes, seconds };
            };

            setTimeLeft(calculateTimeLeft());

            const timer = setInterval(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [eventData]);
    if (!eventData?.subTitle) {
        return <Loading />;
    }
    if (!timeLeft) {
        return <div>الحدث قد بدأ!</div>;
    }

    const timeRemaining = `${timeLeft.days} ${t("day")} ، ${timeLeft.hours} ${t("hour")} ، ${timeLeft.minutes} ${t("min")}، ${timeLeft.seconds} ${t("sec")}`;

    return (
        <div>
            <div className='h-screen carnaval relative'>
                <div className='bg-secondry'>
                    <Navbar />
                </div>
                <div className='overlay'></div>
                <div className=' h-full w-full relative z-10 gap-6 flex-center flex-col'>
                    <h2 data-aos="zoom-in" className='text-2xl text-gold font-bold'>{t("be-wait")}</h2>
                    <h2 data-aos="zoom-in" data-aos-dealy="600" className='text-3xl text-gold font-extrabold'>{t("carnaval")}</h2>
                    <p data-aos="zoom-in" data-aos-dealy="800" className='text-white text-4xl text-center'>{timeRemaining}</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Home;
