
import React, { useState } from 'react'
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { handleUpdateItem, API_URL, handleAddItem } from '../../utils/data';
import Table from '../common/Table';
import EditForm from './EditForm';
import { useTranslation } from 'react-i18next';
import DeletePopUp from './DeletePopUp';
import AddForm from './AddForm';

const SectionDetails = () => {

    const { t } = useTranslation();
    const { showEditForm,showAddProperty, setShowAddProperty, setShowEditForm, showAddForm, setShowAddForm, section, setShowdeletePopup, showdeletePopup } = useStateContext() as GlobalStateContext;
    const [selectedSection, setSelectedSection] = useState<any | null>(null);

    const handleEditSection = (data: any) => {
        if (selectedSection) {
            handleUpdateItem(data, "HomePageManagement/Update/PageSection")
            localStorage.setItem("section", JSON.stringify(data));
        }
    }
    const handleAddProperty = (data: any) => {
        data.homePageSectionId = section.id;
        handleAddItem("HomePageManagement/Update/PageSectionProperty", data)
        console.log("New Product Data:", data);
    };
    return (
        <div>
            {section && (
                <div>
                    <div className='py-8'>
                        <h3 className='font-bold text-2xl'>{t("sectionDetails")}</h3>
                        <Table
                            headers={[t("element"), t("details")]}
                            data={Object.entries(section)}
                            renderRow={([key, value]: [string, any]) => (
                                key !== "homePageId" &&
                                key !== "id" &&
                                key !== "createDate" &&
                                key !== "createdByName" &&
                                key !== "homePageSectionId" &&
                                value !== "" && value !== null && value !== undefined && (
                                    <tr key={key} className=' table'>
                                        <td className='font-bold'>{t(key)}</td>
                                        <td className='text-primary'>
                                            {key === "image" && value ? (
                                                <div className='w-20 h-20 flex-center'>
                                                    <img src={`${API_URL}/imgs/${value}`} className='w-full' alt="no-image" />
                                                </div>
                                            ) : (
                                                typeof value === 'string' && value.length > 0 ? value : '-'
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        />
                        <div className='grid pt-5'>
                            <div className='justify-self-end flex gap-4 '>
                                <button
                                    onClick={() => {
                                        setShowAddProperty(true)
                                    }} className='bg-blue-600 text-lg rounded-lg py-1 px-3 text-white'>
                                    {t("add-prop")}
                                </button>
                                <button onClick={() => {
                                    setSelectedSection(section);
                                    setShowEditForm(true);
                                }} className='bg-green-600  text-lg rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button
                                    onClick={() => {
                                        setSelectedSection(section);
                                        console.log("item", section)
                                        setShowdeletePopup(true)
                                    }}
                                    className='bg-red-600 text-lg rounded-lg py-1 px-3 text-white'>
                                    {t("delete")}
                                </button>
                            </div>
                        </div>
                        {showdeletePopup && selectedSection && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={`HomePageManagement/Delete/PageSection/${selectedSection?.id?.value}`} id={selectedSection?.id?.value} content={true} />}
                        {showEditForm && selectedSection && (
                            <EditForm
                                setShowEditForm={setShowEditForm}
                                fields={Object.keys(selectedSection)
                                    .filter((key) =>
                                        key !== "homePageId"
                                    )
                                    .map((key) => ({
                                        name: key,
                                        type: key === 'image' ? 'file' : 'text',
                                        label: t(`${key}`)
                                    }))}
                                initialData={selectedSection}
                                onSubmit={handleEditSection}
                            />
                        )}
                        {showAddProperty && <AddForm
                            fields={[
                                { name: 'title', type: 'text', label: t('section-name') },
                                { name: 'link', type: 'text', label: t('link') },
                                { name: 'linkTitle', type: 'text', label: t('linkTitle') },
                                { name: 'content', type: 'text', label: t('details') },
                                { name: 'icon', type: 'text', label: t('icon') },
                                { name: 'image', type: 'file', label: t('image') }
                            ]}
                            onSubmit={handleAddProperty}
                        />}
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default SectionDetails