import React from 'react'
import Home from '../../components/news/Home'

const News = () => {
  return (
    <div>
        <Home/>
    </div>
  )
}

export default News