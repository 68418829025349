import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import "./i18n"
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import { StateContext } from './context/StateContext';
import ProtectedRoute from './routes/privateRoute/ProtectedRoute';

import { Toaster } from 'react-hot-toast';
import Home from './components/Home/Home';
import ContentPage from './routes/dashbord/ContentPage';
import NotFound from './components/common/NotFound';
import Login from './routes/Login';
import Events from './routes/Events';
import ContactUs from './routes/ContactUs';
import SiteInfo from './routes/dashbord/SiteInfo';
import Messages from './routes/dashbord/Messages';
import News from './routes/news/News';
import BlogDetails from './components/news/BlogDetails';
import NewsLayout from './routes/news/NewsLayout';
import KaramaLayout from './routes/karama/KaramaLayout';
import Karama from './routes/karama/Karama';
import FastivalDetails from './components/karama/FastivalDetails';


const router = createBrowserRouter([
    {
        path: "/",
        caseSensitive:false,
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            {path:"/login", element:<Login/>},
            {path:"/contact-us", element:<ContactUs/>},
            {path:"/news", element:<NewsLayout/>,
                children: [
                    { index: true, element: <News /> },
                    {path:"details", element:<BlogDetails/>},
                ]
            },
            {path:"/karama", element:<KaramaLayout/>,
                children: [
                    { index: true, element: <Karama /> },
                    {path:"details", element:<FastivalDetails/>},
                ]
            },
            {path:"/events", element:<Events/>},
            
            {
                path: "/dashbord",
                element: <ProtectedRoute />,
                children: [
                    { index: true, element: <ContentPage /> },
                    { path: "content-manage/:pageId", element: <ContentPage/> },
                    { path: "siteInfo", element: <SiteInfo/> },
                    { path: "messages", element: <Messages/> }
                ]
            },
            { path: "*", element: <NotFound /> },
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <StateContext>
            <RouterProvider router={router}/>
            <Toaster />
        </StateContext>
    </React.StrictMode>
);