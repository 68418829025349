import React, { useEffect, useState } from 'react'
import Hero from './Hero'
import Footer from '../common/Footer'
import ContactForm from './ContactForm'
import Loading from '../ui/Loading'
import useFetch from '../../custom hooks/UseFetch'
import { fetchPageData } from '../../utils/data'
import Address from './Address'

const Home = () => {
    const { data: pagesData } = useFetch<any>(fetchPageData);
    const [heroData, setHeroData] = useState<any>(null);
    const [mapData, setMapData] = useState<any>(null);
    const [addressData, setAddressData] = useState<any>(null);
    useEffect(() => {
        if (pagesData?.data) {
            const homePage = pagesData.data[3];
                const hero = homePage.homePageSections[0];
                const map = homePage.homePageSections[1];
                const address = homePage.homePageSections[2];
                setHeroData(hero);
                setMapData(map)
                setAddressData(address)
            
        }
    }, [pagesData]);
    if (!heroData) {
        return <Loading />;
    }
    return (
        <div>
            <Hero heroData={heroData} />
            <ContactForm mapData={mapData} />
            <Address addressData={addressData}/>
            <Footer />
        </div>
    )
}

export default Home