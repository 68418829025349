
import { useTranslation } from 'react-i18next'
import { steps } from '../../constant';
import { API_URL } from '../../utils/data';

const Start = ({startData}:any) => {
    const { t } = useTranslation();
    return (
        <section
            className='relative bg-fixed p-container main-padding'
            style={{
                backgroundImage: `url("${API_URL}/imgs/${startData?.image}")`,
                backgroundSize: "cover",
                backgroundPosition:"center"
            }}>
            <div className='overlay'></div>
            <div className='z-10 relative text-white '>
                <div className=' flex-center flex-col text-center main-padding'>
                <h1 data-aos="fade-down" className='text-lg font-bold  pb-3'>{t("how-start")}</h1>
                <h2 data-aos="fade-down" data-aos-delay="100" className=' text-3xl font-extrabold  text-gold pb-5 '>{startData?.content}</h2>
                </div>
                <ul className=' flex-center xs:flex-col md:flex-row xs:gap-16 md:gap-32'>
                    {startData?.homePageSectionProperties.map((step:any,index:number)=>(
                        <li key={index} className=' flex-center flex-col gap-5'>
                            <div className='text-4xl flex-center w-24 h-24 border-2 rounded-full border-gray-500'>
                                <p>{index+1}</p>
                            </div>
                            <p>{step.content}</p>
                        </li>
                    ))}
                </ul>
            </div>

        </section>
    )
}

export default Start