import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Table from '../../components/common/Table';
import EditForm from '../../components/dashbord/EditForm';
import DashbordTitle from '../../components/ui/DashbordTitle';
import Loading from '../../components/ui/Loading';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import useFetch from '../../custom hooks/UseFetch';
import { Product } from '../../types';
import { API_URL, fetchSiteInfo, handleUpdateItem } from '../../utils/data';

const SiteInfo = () => {
    const { t } = useTranslation();
    const { showEditForm, setShowEditForm } = useStateContext() as GlobalStateContext;
    const [selectedItem, setSelectedItem] = useState<Product | null>(null);
    const { data: siteInfoData, loading, } = useFetch<any>(fetchSiteInfo);
    const siteInfo = siteInfoData?.data

    const handleEditSiteInfo = (data: any) => {
        if (selectedItem) {
            handleUpdateItem(data, "SiteInfo")
            console.log("Edited Product Data:", data);
        }

    };
    if (loading) return <Loading />
    return (
        <div className=' bg-darkColor pb-8 px-6'>
            {siteInfo.length > 0 && (
                siteInfo.map((item: any, index: number) => (
                    <div key={index} >
                        <div className='py-8'>
                        <h3 className='font-bold text-2xl'>{t("sectionDetails")}</h3>
                            <Table
                                headers={[t("element"), t("details")]}
                                data={Object.entries(item)}
                                renderRow={([key, value]: [string, any]) => (
                                    key !== "homePageId" && key !== "sectionMode" && key !== "id" &&
                                    key !== "createDate" && key !== "createdByName" && key !== "mainTitle" &&
                                    (
                                        <>
                                            <td className='font-bold'>{t(key)}</td>
                                            <div className='text-primary'>{
                                                key === "image" && value ? (
                                                    <td>
                                                        <div className='w-20 h-20 flex-center'>
                                                            <img src={`${API_URL}/imgs/${value}`} className='w-full' alt="no-img" />
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td>{typeof value === 'string' && value.length > 0 ? value : t("no-data-available")}</td>
                                                )
                                            }
                                            </div>
                                        </>
                                    )
                                )}
                            />
                            <div className='grid pt-5'>
                                <button onClick={() => {
                                    setSelectedItem(item);
                                    setShowEditForm(true);
                                }} className='bg-green-600 justify-self-end text-xl rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                            </div>
                            {showEditForm && selectedItem && <EditForm
                                setShowEditForm={setShowEditForm}
                                fields={[
                                    { name: 'number', type: 'text', label: t("number") },
                                    { name: 'name', type: 'text', label: t("name") },
                                    { name: 'description', type: 'textarea', label: t("description") },
                                    { name: 'facebook', type: 'text', label: t("facebook") },
                                    { name: 'instagrham', type: 'text', label: t("instagrham") },
                                    { name: 'youtube', type: 'text', label: t("youtube") },
                                    { name: 'twitter', type: 'text', label: t("twitter") },
                                    { name: 'image', type: 'file', label: t("image") }
                                ]}
                                initialData={selectedItem}
                                onSubmit={handleEditSiteInfo}
                            />}
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default SiteInfo