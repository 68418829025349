
import AddButton from './AddButton'
import { GlobalStateContext, useStateContext } from '../../context/StateContext'
interface DashbordTitleProps {
    title: string
    add:boolean
}
const DashbordTitle = ({ title ,add}: DashbordTitleProps) => {
    const { setShowAddForm } = useStateContext() as GlobalStateContext;
    return (
        <div className='bg-white border-s-8 border-primary p-3 flex  sm:justify-between  gap-5 xs:flex-col sm:flex-row w-full'>
            <h1 className='text-2xl font-semibold '>{title}</h1>
             <div className='flex items-center sm:justify-center xs:justify-start gap-6'>
              {/*  <form className='flex-center   border-b-2 xs:w-44 sm:w-52 lg:w-72 '>
                    <input type="text"
                        className=' focus:outline-none w-full' />
                    <button className='text-blue-600 ' type="submit"><FaSearch /></button>
                </form> */}
                {add&&<AddButton setShowAddForm={setShowAddForm} />}
            </div>
        </div>

    )
}

export default DashbordTitle