import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const NotFound = () => {
    return (
        <div className='h-screen'>
            <div className=' bg-secondry'>
                <Navbar />
            </div>
            
            <div className="flex-center flex-col  text-center ">
                <div className=' relative  overflow-hidden h-96'>
                    <img src="/asset/come.jpg" className='w-full max-h-full' loading='lazy' alt="not-found" />
                </div>
                <Link to="/" className="px-4 py-2 bg-primary text-white rounded-full hover:bg-secondary">
                    Go Home
                </Link>
            </div>
        </div>
    );
}

export default NotFound