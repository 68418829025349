import React, { createContext, useContext, useState } from "react";

export interface GlobalStateContext {
    showdeletePopup: boolean
    showAddForm: boolean
    showEditForm: boolean
    section: any;
    showAddPage:boolean;
    showAddProperty:boolean;
    activeBlog:any;
    setActiveBlog: React.Dispatch<React.SetStateAction<any>>;
    setSection: React.Dispatch<React.SetStateAction<any>>;
    setShowdeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAddForm: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAddPage:React.Dispatch<React.SetStateAction<boolean>>;
    setShowAddProperty:React.Dispatch<React.SetStateAction<boolean>>
}
const Context = createContext<GlobalStateContext | null>(null);
interface StateContextProps {
    children: React.ReactNode;
}
export const StateContext = ({ children }: Readonly<StateContextProps>) => {
    const [showdeletePopup, setShowdeletePopup] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showAddPage, setShowAddPage] = useState<boolean>(false);
    const [showAddProperty, setShowAddProperty] = useState<boolean>(false);
    const [section, setSection] = useState<any>();
    const [activeBlog,setActiveBlog] = useState<any>();
    return (
        <Context.Provider value={{
            showEditForm,
            setShowEditForm,
            showAddForm,
            setShowAddForm,
            showdeletePopup,
            setShowdeletePopup,
            section,
            setSection,
            showAddPage,
             setShowAddPage,
             showAddProperty,
              setShowAddProperty,
              activeBlog,
              setActiveBlog
        }}>
            {children}
        </Context.Provider>
    );
}
export const useStateContext = () => useContext(Context);