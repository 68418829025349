import React, { useState } from 'react'
import Table from '../common/Table';
import { useTranslation } from 'react-i18next';
import { API_URL, handleUpdateItem } from '../../utils/data';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import EditForm from './EditForm';
import DeletePopUp from './DeletePopUp';
interface PropertyProps {
    properties: any[]
}
const Property = ({ properties }: PropertyProps) => {
    const { t } = useTranslation();
    const [selectedProperty, setSelectedProperty] = useState<any | null>(null);
    const { showEditForm, setShowEditForm, showdeletePopup, setShowdeletePopup } = useStateContext() as GlobalStateContext;
    const handleEditSectionProperty = (data: any) => {
        if (selectedProperty) {
            handleUpdateItem(data,"HomePageManagement/Update/PageSectionProperty")
        }
    };
    if (properties.length === 0) return null
    return (
        <div>
            <h3 className='font-bold text-2xl'>{t("sectionProperty")}</h3>
            {properties.map((item: any, index) => (
                <div key={index} >
                    <div className='py-8'>

                        <Table
                            headers={[t("element"), t("details")]}
                            data={Object.entries(item)}
                            renderRow={([key, value]: [string, any]) => (
                                key !== "homePageId" &&
                                key !== "id" &&
                                key !== "propertyMode" &&
                                key !== "createDate" &&
                                key !== "createdByName" &&
                                key !== "homePageSectionId" &&
                                value !== "" && value !== null && value !== undefined && (
                                    <tr key={key}>
                                        <td className='font-bold'>{t(key)}</td>
                                        <td className='text-primary'>

                                            {key === "image" && value ? (
                                                <div className='w-20 h-20 flex-center'>
                                                    <img src={`${API_URL}/imgs/${value}`} className='w-full' alt="no-image" />
                                                </div>
                                            ) : (

                                                typeof value === 'string' && value.length > 0 ? value : '-'
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        />

                        <div className='grid pt-5'>
                            <div className=' justify-self-end flex gap-5'>
                                <button onClick={() => {
                                    setSelectedProperty(item);
                                    setShowEditForm(true);
                                }} className='bg-green-600 justify-self-end text-xl rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button
                                    onClick={() => {
                                        setSelectedProperty(item);
                                        
                                        setShowdeletePopup(true)
                                    }}
                                    className='bg-red-600 rounded-lg py-1 px-3 text-white'>
                                    {t("delete")}
                                </button>
                            </div>
                        </div>
                        {showdeletePopup &&selectedProperty&& <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={`HomePageManagement/Delete/PageSectionProperty/${selectedProperty?.id?.value}`} id={selectedProperty!.id} content={true}/>}

                        {showEditForm && selectedProperty && (
                            <EditForm
                                setShowEditForm={setShowEditForm}
                                fields={Object.keys(selectedProperty)
                                    .filter((key) =>
                                        key !== "homePageId"
                                    )
                                    .map((key) => ({
                                        name: key,
                                        type: key === 'image' ? 'file' : 'text',
                                        label: t(`${key}`)
                                    }))}
                                initialData={selectedProperty}
                                onSubmit={handleEditSectionProperty}
                            />
                        )}
                    </div>
                </div>
            ))
            }</div>
    )
}

export default Property