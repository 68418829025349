import React from 'react'
import SectionText from '../ui/SectionText'
import { services } from '../../constant';
import MainLink from '../ui/MainLink';
import { API_URL } from '../../utils/data';

const Services = ({serviceData}:any) => {
    return (
        <section className='  p-container main-padding bg-darkColor overflow-hidden'>
            <div className=''>
                <SectionText title={serviceData?.mainTitle} subtitle={serviceData?.content} />
                <ul className=' grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 p-container main-padding'>
                    {serviceData.homePageSectionProperties.map((service:any, index:number) => (
                        <li key={index} className='relative' data-aos="fade-up" data-aos-delay={`${index + 1}00`}>
                            <div className='service-overlay'></div>
                            <div className='h-[450px] w-full'>
                                <img className=' h-full w-full' src={`${API_URL}/imgs/${service?.image}`} alt="service-image" loading='lazy' />
                            </div>
                            <div className=' absolute bottom-0 pb-5 px-2 text-white z-10'>
                                <h3 className='pb-1 text-xl font-bold'> {service.title}</h3>
                                <p >{service.content}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                <div data-aos="fade-up" data-aos-delay="500" 
                className='p-10 w-full text-center '>
                    <MainLink title={serviceData?.linkTitle} href={serviceData?.link} />
                </div>
            </div>
        </section>
    )
}

export default Services