import  { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { handleAuth } from '../utils/data';
const Login = () => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        const loginData = {
            "phone":phone,
            "password":password
        }
        e.preventDefault();
        handleAuth(loginData, 'Login successful', navigate);
    };
    return (
        <div>
            <div className=' flex flex-col justify-center py-10'>
                <div className="flex-center flex-col w-full ">
                    <p className='sm:text-3xl xs:text-2xl font-bold text-black'>{t("login-now")}</p>
                    <form onSubmit={handleLogin}>
                        <div className=' flex flex-col  gap-2 py-8'>
                            <label>{t('phone')}</label>
                            <input
                                type="text"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="border p-2 w-full focus:outline-none"
                            />
                            <label>{t('Password')}</label>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="border p-2 w-full focus:outline-none"
                            />
                        </div>
                        <input type="submit"
                            value={t("login")}
                            className=" w-full cursor-pointer text-xl mb-3 flex-center bg-primary border-[1px] rounded-lg border-none gap-1 py-3 text-white"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login