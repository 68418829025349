
import { Link } from 'react-router-dom'

const Logo = () => {

  return (
    <Link to={"/"} className=' w-32'>
      <img className=' w-full' src={`/asset/logo.png`} alt='logo' loading='lazy' />
    </Link>
  )
}

export default Logo