import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import useFetch from '../../custom hooks/UseFetch';
import { API_URL, fetchBlog, fetchMessage, handleAddItem, handleUpdateItem } from '../../utils/data';
import Loading from '../../components/ui/Loading';
import DashbordTitle from '../../components/ui/DashbordTitle';
import Table from '../../components/common/Table';
import AddButton from '../../components/ui/AddButton';
import DeletePopUp from '../../components/dashbord/DeletePopUp';
import AddForm from '../../components/dashbord/AddForm';
import EditForm from '../../components/dashbord/EditForm';

const Messages = () => {
    const { t, i18n } = useTranslation();
    const { showAddForm, showEditForm, showdeletePopup, setShowAddForm, setShowEditForm, setShowdeletePopup } = useStateContext() as GlobalStateContext;
    const [selectedMessage, setSelectedMessage] = useState<any>(null);
    const [showAddBolg, setShowAddBlog] = useState(false);
    const { data: MessageData, loading } = useFetch<any>(fetchMessage);
    const messages = MessageData?.data;

    if (loading) return <Loading />
    return (
        <div className='bg-darkColor py-10 px-6'>
            <div className=''>
                <h3 className='font-bold text-2xl '>{t("sectionDetails")}</h3>
                <Table
                    headers={[t("title"), t("phone"), t("email"), t("message"), t("actions")]}
                    data={messages || []}
                    renderRow={(message: any) => (
                        <>
                            <td className='font-bold'>{message.title}</td>
                            <td className=' text-primary'>{message.phone}</td>
                            <td className=' text-primary'>{message.email}</td>
                            <td className=' text-primary'>{message.content}</td>
                            <td>
                                <div className='flex gap-4'>
                                    <button onClick={() => {
                                        setSelectedMessage(message);
                                        setShowdeletePopup(true)
                                    }
                                    } className='bg-red-600 rounded-lg py-1 px-3 text-white'>{t("delete")}</button>
                                </div>
                            </td>
                        </>
                    )}
                />
                
                {showdeletePopup && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={`ContactForm/Delete/${selectedMessage?.id?.value}`} id={selectedMessage!.id} content={true} />}
            </div>
        </div>
    );
};

export default Messages