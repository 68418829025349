import React, { useEffect, useState } from 'react';
import Logo from '../ui/Logo';
import { navlinks, navlinks_en } from '../../constant';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';
import { FaBars, FaUser } from 'react-icons/fa';
import '../../aos.css';
import MobileNavbar from '../ui/MobileNavbar';
import Cookies from 'js-cookie';
import LanguageSwitcher from '../ui/LanguageSwitcher';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const isAuthenticated = !!Cookies.get('token');

    const NavbarLinks = i18n.language === 'ar' ? navlinks : navlinks_en;

    
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight) {
                setIsScrolled(true); 
            } else {
                setIsScrolled(false); 
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); 
    }, []);

    useEffect(() => {
        Aos.init({
            easing: 'ease-in-out',
            duration: 1000,
        });
    }, []);

    return (
        <header data-aos="slide-down" className={`w-full z-50 top-0 transition-all duration-300 ${isScrolled ? 'fixed bg-secondry' : 'relative'}`}>
            <div className="p-container ">
                <div className="flex-between text-lg font-semibold text-white">
                    <Logo />
                    <ul className="flex-center gap-6 xs:hidden md:flex">
                        {NavbarLinks.map((link, index) => (
                            <li key={index}>
                                <NavLink
                                    to={link.href}
                                    className={({ isActive }) =>
                                        `${isActive ? 'bg-primary text-white px-3 py-1 rounded-3xl' : ''} w-full transition-all duration-300`
                                    }
                                >
                                    {link.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className="flex-center gap-5">
                        <LanguageSwitcher />
                        {isAuthenticated ? (
                            <Link to="/dashbord">
                                <FaUser />
                            </Link>
                        ) : (
                            <Link className="xs:hidden md:flex transition-all duration-300 hover:text-black" to="/login">
                                {t('login')}
                            </Link>
                        )}
                        <button
                            type="button"
                            onClick={() => setOpen(true)}
                            title="open menu"
                            className="xs:flex md:hidden  text-[26px]"
                        >
                            <FaBars />
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <MobileNavbar open={open} setOpen={setOpen} isAuthenticated={isAuthenticated} />
            </div>
        </header>
    );
};

export default Navbar;
