import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { API_URL, handleSendMessage } from '../../utils/data';

const ContactForm = ({ mapData }: any) => {
    const { t } = useTranslation()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        const contactData = {
            "title":name,
            "content":message,
            "email":email,
            "phone":phone
        }
        e.preventDefault();
        handleSendMessage(contactData, 'Sent successfully');
    };
    return (
        <div className=' overflow-hidden  ' >
            <div className=' flex justify-around items-center xs:flex-col md:flex-row'>
                <div className='flex-1 w-full p-container main-padding' >
                    <p className='mb-10  text-2xl text-gold font-bold text-nowrap'>{t("ContactInfo")}</p>
                    <form className='flex flex-col mx-auto  ' onSubmit={handleSubmit}>
                        <label htmlFor='name'>{t("name")}</label>
                        <input type='text' title='name' className='border-2 xs:w-full md:w-1/2 mb-5 mt-2 rounded-lg p-2 focus:outline-none' required onChange={(e) => { setName(e.target.value)}} />
                        <label htmlFor='name'>{t("phone")}</label>
                        <input type='text' title='name' className='border-2 xs:w-full md:w-1/2 mb-5 mt-2 rounded-lg p-2 focus:outline-none' required onChange={(e) => { setPhone(e.target.value)}} />
                        <label htmlFor='email'>{t("email-address")}</label>
                        <input type='email' title='email' className='border-2 xs:w-full md:w-1/2 mb-5 mt-2 rounded-lg p-2 focus:outline-none' required onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor='message'>{t("message")}</label>
                        <textarea name='message' title='message'  className='border-2 xs:w-full md:w-1/2 mb-5 mt-2 rounded-lg p-2 focus:outline-none  h-[130px] max-h-[130px]' onChange={(e) => setMessage(e.target.value)}></textarea>
                        <input type="submit" value={t("send")} className=" bg-secondry text-white cursor-pointer w-1/2 mb-5 mt-2 rounded-lg p-2 focus:outline-none"/>
                    </form>
                </div>
                <div className='flex-1 flex-center'>
                    <div className=' w-full h-[700px]'>
                        <img className='w-full h-full' src={`${API_URL}/imgs/${mapData?.image}`} alt="vision" loading='lazy' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm